import {Link, useNavigate} from "react-router-dom"
import Header from "../components/Header"
import {IoMdArrowRoundBack, IoMdSettings} from "react-icons/io"
import {calculateAge} from "../utils/UtilMethods"
import AvatarPicture from "../components/AvatarPicture"
import Footer from "../components/Footer"
import React, {useContext, useState} from "react"
import {FiAlertCircle} from "react-icons/fi"
import {AuthContext} from "../contexts/AuthContext"
import axios from "axios"


const ProfileDelete = () => {
    const navigate = useNavigate()

    const {authUser, setAuthUser} = useContext(AuthContext)

    const [formData, setFormData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData({
            ...formData, [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        document.getElementById("validationModal").showModal()
    }

    const handleDelete = async () => {
        const errors = validatePassword(formData)

        setErrors(errors)
        if (Object.keys(errors).length > 0) {
            return
        }

        try {
            setIsLoading(true)
            const API_URL = process.env.API_URL || 'http://localhost:3001'
            const response = await axios.post(API_URL + `/user/delete/${authUser._id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 200) {
                localStorage.removeItem('token')
                setAuthUser(null)
                navigate('/')
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    setErrors(error.response.data)
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div className="flex-grow mx-auto max-w-7xl w-11/12 mb-8">
                <div className="flex justify-between items-center mb-5">
                    <Link to="/profile-edit" className="rounded-full hover:bg-base-300 transition">
                        <IoMdArrowRoundBack size={56} className="text-white"/>
                    </Link>
                    <h2 className="text-5xl text-center">Supprimer votre compte</h2>
                    <p className="mr-14"></p>
                </div>
                <div className="bg-base-300 rounded-2xl px-10 py-5 flex justify-between items-center mb-5">
                    <div className="flex gap-6 justify-start items-center">
                        <div className="ring-primary ring-offset-base-100 w-16 rounded-full ring ring-offset-2">
                            <img className="rounded-full" src={`https://robohash.org/${authUser.pfpFileName}?set=set2`}
                                 alt="Photo de profil"/>
                        </div>
                        <div>
                            <h3 className="text-3xl">{authUser.username}</h3>
                            <p className="text-xl">{calculateAge(authUser.dob)} ans</p>
                        </div>
                    </div>

                    <Link to="/profile-edit" className="rounded-full hover:bg-base-300 transition"><IoMdSettings
                        size={32}
                        className="text-white"/></Link>
                </div>

                <div className="bg-base-300 rounded-2xl px-10 py-5">
                    <p className="text-2xl mb-3 pb-3 border-b-2 border-white">Pour supprimer votre compte, entrez votre mot de passe.</p>

                    <form onSubmit={handleSubmit} className="mb-3">
                        <label className="form-control w-full max-w-xl mt-3">
                            <div className="label">
                                <span className=" text-xl">Mot de passe</span>
                            </div>
                            <input type="password" placeholder="Tapez ici" name="password" value={formData.password}
                                   onChange={handleChange}
                                   className="input input-bordered rounded-full w-full"/>
                        </label>
                        {errors.password && (
                            <div className="flex justify-start items-center gap-1">
                                <FiAlertCircle size={24} className="text-red-500"/>
                                <p className="text-red-500 text-lg">{errors.password}</p>
                            </div>
                        )}

                        {isLoading ? (
                            <button className="btn btn-error rounded-full w-52 mt-5">
                                <span className="loading loading-spinner"></span>
                                Chargement
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-error rounded-full w-52 mt-5">
                                Supprimer votre compte
                            </button>
                        )}
                    </form>
                </div>
            </div>
            <Footer/>

            <dialog id="validationModal" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-3xl">Supprimer votre compte</h3>
                    <p className="py-4 text-xl">Souhaitez-vous vraiment supprimer votre compte? Cette action est irréversible.</p>
                    <div className="modal-action">
                        <form method="dialog">
                            <button onClick={handleDelete} className="btn btn-error mr-2 text-lg">Confirmer</button>
                            <button className="btn text-lg">Annuler</button>
                        </form>
                    </div>
                </div>
            </dialog>
        </div>
    )
}

export default ProfileDelete

const validatePassword = (formData) => {
    const errors = {}

    if (!formData.password) {
        errors.password = "Ce champ est requis."
    }

    return errors
}