import React, {useState, useEffect, useContext} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import ForumThread from '../components/ForumThread';
import CategoryForm from '../components/CategoryForm';
import { BiSolidPlusCircle, BiSolidEdit } from "react-icons/bi";
import ThreadForm from "../components/ThreadForm";
import {AuthContext} from "../contexts/AuthContext";
import Header from "../components/Header"
import Footer from "../components/Footer"

const Forum = () => {
    const [forumThreads, setForumThreads] = useState([]);
    const [categories, setCategories] = useState([]);
    const [editingCategory, setEditingCategory] = useState(null);
    const {authUser, setAuthUser} = useContext(AuthContext);
    const API_URL = process.env.API_URL || 'http://localhost:3001';

    useEffect(() => {
        document.title = "Forum | RetroRealm"
    }, [])

    useEffect(() => {
        const fetchThreads = async () => {
            try {
                const response = await axios.get(`${API_URL}/forum`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                    }
                });
                setForumThreads(response.data.length ? response.data : []);
            } catch (error) {
                console.log(error);
            }
        };
        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${API_URL}/forum/categories`, {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem("token"))}`,
                    }
                });
                setCategories(response.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchThreads();
        fetchCategories();
    }, []);

    useEffect(() => {
        if (editingCategory) {
            const modal = document.getElementById('edit_category_modal');
            if (modal) {
                modal.showModal();
            }
        }
    }, [editingCategory]);

    const addCategory = (newCategory) => {
        setCategories((prevCategories) => [...prevCategories, newCategory]);
    };

    const updateCategory = (updatedCategory) => {
        setCategories((prevCategories) =>
            prevCategories.map((category) =>
                category._id === updatedCategory._id ? updatedCategory : category
            )
        );
    };

    const deleteCategory = (categoryId) => {
        setCategories((prevCategories) =>
            prevCategories.filter((category) => category._id !== categoryId)
        );
    };

    const handleEditClick = (category) => {
        setEditingCategory(category);
    };

    const handleCloseModal = (isEdit, isCategory) => {
        let modal;
        if (isCategory) {
            if (isEdit) {
                setEditingCategory(null);
                modal = document.getElementById('edit_category_modal');
            }
            else {
                modal = document.getElementById('category_modal');
            }
        }
        else
            modal = document.getElementById('thread_modal');
        if (modal) {
            modal.close();
        }
    };

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header />
            <main className="container flex-grow">
                <Link to={'/forum'}><h2 className="pixel-font my-4 text-5xl inline-block">Forum</h2></Link>
                <div className="flex justify-between items-start">
                    <div className="rounded-2xl border-4 border-accent me-4 w-1/4">
                        <div
                            className="flex flex-row align-middle justify-between h-16 bg-accent p-3 rounded-t text-black font-bold">
                            <h3>Catégories</h3>
                            {authUser.userType === "ADMIN" && (
                                <>
                                    <button
                                        className="hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300"
                                        onClick={() => document.getElementById('category_modal').showModal()}>
                                        <BiSolidPlusCircle size={40} />
                                    </button>
                                    <CategoryForm addCategory={addCategory} onClose={handleCloseModal} />
                                </>
                            )}
                        </div>
                        <ul>
                            {categories.length > 0 ? (
                            categories.map((category) => (
                                <div key={category._id} className="relative m-3 hover:opacity-80 hover:scale-105 ease-in-out duration-300 delay-300">
                                    <Link to={`/forum/categories/${category._id}`}
                                          className="flex items-center justify-center rounded-2xl bg-primary text-black font-bold h-10 relative ">
                                        <span
                                            className={`leading-normal absolute left-1/2 transform -translate-x-1/2 whitespace-nowrap ${category.name.length > 10 ? 'text-xl' : 'text-2xl'}`}>
                                            {category.name}
                                        </span>
                                    </Link>
                                    {authUser.userType === "ADMIN" && (
                                        <button
                                            className="absolute right-2 top-1/2 transform -translate-y-1/2 z-10 text-black"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEditClick(category);
                                            }}
                                        >
                                            <BiSolidEdit size={26} />
                                        </button>
                                    )}
                                </div>
                            ))
                            ) : (
                                <li className="p-4 text-center text-xl">Aucune catégorie disponible</li>
                            )}
                        </ul>
                    </div>
                    <div className="rounded-2xl border-4 border-primary w-3/4">
                        <div
                            className="flex flex-row align-middle justify-between h-16 bg-primary p-3 rounded-t text-black font-bold">
                            <h3>Discussions récentes</h3>
                            <button className="hover:opacity-80 hover:scale-125 ease-in-out duration-300 delay-300"
                                    onClick={() => document.getElementById('thread_modal').showModal()}>
                                <BiSolidPlusCircle size={40} />
                            </button>
                            <ThreadForm
                                onClose={handleCloseModal}
                                categories={categories}
                                user={authUser._id}
                            />
                        </div>
                        <ul>
                            {forumThreads.length > 0 ? (
                            forumThreads.map((thread) => (
                                <ForumThread
                                    key={thread._id}
                                    id={thread._id}
                                    title={thread.title}
                                    category={thread.category}
                                    createdAt={thread.createdAt}
                                    user={thread.user}
                                    isLiked={thread.isLiked}
                                    isDisliked={thread.isDisliked}
                                    likes={thread.likes}
                                    dislikes={thread.dislikes}
                                    replies={thread.replies.length}
                                />
                            ))
                            ) : (
                                <li className="p-4 text-center text-xl">Aucune discussion disponible</li>
                            )}
                        </ul>
                    </div>
                </div>
                {editingCategory && (
                    <CategoryForm
                        category={editingCategory}
                        updateCategory={updateCategory}
                        deleteCategory={deleteCategory}
                        onClose={handleCloseModal}
                    />
                )}
            </main>
            <Footer />
        </div>
    );
};

export default Forum;