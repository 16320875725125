import {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import { AuthContext } from "../contexts/AuthContext";
import axios from "axios"

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate()

    const {setAuthUser} = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(true)

    const getAuthUser = async (token) => {
        try {
            const API_URL = process.env.API_URL || "http://localhost:3001"
            const response = await axios.get(API_URL + '/user/token', {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            const user = response.data
            setAuthUser(user)
            if (!user.isAccountValidated) {
                navigate('/validate-email')
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'))
        if (!token) {
            navigate('/login')
        }
        getAuthUser(token)
    }, [])

    if (!isLoading) {
        return children
    }
}

export default ProtectedRoute
