import Header from "../components/Header"
import {Link, useNavigate} from "react-router-dom"
import {IoMdArrowRoundBack, IoMdSettings} from "react-icons/io"
import {calculateAge} from "../utils/UtilMethods"
import {FiAlertCircle} from "react-icons/fi"
import Footer from "../components/Footer"
import React, {useContext, useState} from "react"
import {AuthContext} from "../contexts/AuthContext"
import axios from "axios"


const ProfilePasswordEdit = () => {
    const navigate = useNavigate()

    const {authUser, setAuthUser} = useContext(AuthContext)

    const [formData, setFormData] = useState({})
    const [errors, setErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData({...formData, [name]: value})
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const receivedErrors = validateFormData(formData)

        setErrors(receivedErrors)
        if (Object.keys(receivedErrors).length > 0) {
            return
        }

        try {
            setIsLoading(true)
            const API_URL = process.env.API_URL || 'http://localhost:3001'
            const response = await axios.patch(API_URL + `/user/edit/password/${authUser._id}`, formData, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 200) {
                setAuthUser(response.data)
                setFormData({
                    currentPassword: '',
                    newPassword1: '',
                    newPassword2: ''
                })
                setShowToast(true)
                setTimeout(() => {
                    setShowToast(false)
                }, [3000])
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    setErrors(error.response.data)
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div className="flex-grow mx-auto max-w-7xl w-11/12">
                <div className="flex justify-between items-center mb-5">
                    <Link to="/profile-edit" className="rounded-full hover:bg-base-300 transition">
                        <IoMdArrowRoundBack size={56} className="text-white"/>
                    </Link>
                    <h2 className="text-5xl">Modification du mot de passe</h2>
                    <p className="mr-14"></p>
                </div>
                <div className="bg-base-300 rounded-2xl px-10 py-5 flex justify-between items-center mb-5">
                    <div className="flex gap-6 justify-start items-center">
                        <div className="ring-primary ring-offset-base-100 w-16 rounded-full ring ring-offset-2">
                            <img className="rounded-full" src={`https://robohash.org/${authUser.pfpFileName}?set=set2`}
                                 alt="Photo de profil"/>
                        </div>
                        <div>
                            <h3 className="text-3xl">{authUser.username}</h3>
                            <p className="text-xl">{calculateAge(authUser.dob)} ans</p>
                        </div>
                    </div>

                    <Link to="/profile-edit" className="rounded-full hover:bg-base-300 transition"><IoMdSettings
                        size={32}
                        className="text-white"/></Link>
                </div>

                <div className="bg-base-300 rounded-2xl px-10 py-5">
                    <form onSubmit={handleSubmit} className="mb-3">
                        <label className="form-control w-full max-w-xl">
                            <div className="label">
                                <span className=" text-xl">Mot de passe actuel</span>
                            </div>
                            <input type="password" placeholder="Tapez ici" name="currentPassword"
                                   value={formData.currentPassword}
                                   onChange={handleChange}
                                   className="input input-bordered rounded-full w-full"/>
                        </label>
                        {errors.currentPassword && (
                            <div className="flex justify-start items-center gap-1">
                                <FiAlertCircle size={24} className="text-red-500"/>
                                <p className="text-red-500 text-lg">{errors.currentPassword}</p>
                            </div>
                        )}

                        <label className="form-control w-full max-w-xl mt-3">
                            <div className="label">
                                <span className=" text-xl">Nouveau mot de passe</span>
                            </div>
                            <input type="password" placeholder="Tapez ici" name="newPassword1"
                                   value={formData.newPassword1}
                                   onChange={handleChange}
                                   className="input input-bordered rounded-full w-full"/>
                        </label>
                        {errors.newPassword1 && (
                            <div className="flex justify-start items-center gap-1">
                                <FiAlertCircle size={24} className="text-red-500"/>
                                <p className="text-red-500 text-lg">{errors.newPassword1}</p>
                            </div>
                        )}

                        <label className="form-control w-full max-w-xl mt-3">
                            <div className="label">
                                <span className=" text-xl">Confirmez le nouveau mot de passe</span>
                            </div>
                            <input type="password" placeholder="Tapez ici" name="newPassword2"
                                   value={formData.newPassword2}
                                   onChange={handleChange}
                                   className="input input-bordered rounded-full w-full"/>
                        </label>
                        {errors.newPassword2 && (
                            <div className="flex justify-start items-center gap-1">
                                <FiAlertCircle size={24} className="text-red-500"/>
                                <p className="text-red-500 text-lg">{errors.newPassword2}</p>
                            </div>
                        )}

                        {isLoading ? (
                            <button className="btn btn-primary rounded-full w-56 mt-5">
                                <span className="loading loading-spinner"></span>
                                Chargement
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-primary rounded-full w-56 mt-5">
                                Modifier le mot de passe
                            </button>
                        )}
                    </form>
                </div>
            </div>
            <Footer/>

            <div className={`toast toast-top toast-center ${showToast ? "block" : "hidden"}`}>
                <div className="alert alert-success">
                    <span>Mot de passe modifié avec succès</span>
                </div>
            </div>
        </div>
    )
}

export default ProfilePasswordEdit

const validateFormData = (formData) => {
    const errors = {}

    const passwordRegex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$')

    if (!formData.currentPassword) {
        errors.currentPassword = "Ce champ est requis."
    }

    if (!formData.newPassword1) {
        errors.newPassword1 = "Ce champ est requis."
    } else if (!passwordRegex.test(formData.newPassword1)) {
        errors.newPassword1 = "Le mot de passe doit contenir au moins 8 caractères dont une lettre majuscule, une lettre minuscule et un chiffre."
    }

    if (!formData.newPassword2) {
        errors.newPassword2 = "Ce champ est requis."
    } else if (formData.newPassword1 !== formData.newPassword2) {
        errors.newPassword2 = "Les mots de passe ne correspondent pas."
    }

    return errors
}