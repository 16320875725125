import React, {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {IoMdTrash} from "react-icons/io";


const MessageList = (props) => {
    const navigate = useNavigate();

    const [messages, setMessages] = useState([]);
    const [currentBox, setCurrentBox] = useState(props.box);
    const [isLoading, setIsLoading] = useState(true)


    const fetchMessages = async () => {
        setIsLoading(true)
        setMessages([])
        let url
        const API_URL = process.env.API_URL || "http://localhost:3001"
        switch (props.box) {
            case "INBOX": url =  `${API_URL}/message/received`; break;
            case "OUTBOX": url =  `${API_URL}/message/sent`; break;
            case "TRASHED": url =  `${API_URL}/message/trashed`; break;
        }
        try {
            //url = props.box === "INBOX" ? `${API_URL}/message/received` : `${API_URL}/message/sent`
            console.log("Fetching... " + url)
            const response = await axios.get(url, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            console.dir(response.data)
            setMessages(response.data)
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fetchMessages()
            // make sure to catch any error
            .catch(console.error);
    }, [currentBox])

    useEffect(() => {
        setCurrentBox(props.box)
    }, [props.box])


    const MessageItem = (props) => {
        let subject = props.subject;
        if (subject.length > 25) {
            subject = subject.substring(0, 25);
            subject += "..."
        }

        const deleteMessage = async () => {

            try {
                const API_URL = process.env.API_URL || "http://localhost:3001"
                const url = `${API_URL}/message/delete/${props.id}`

                const response = await axios.delete(url, {
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                    }
                })
            } catch (error) {
                console.error(error)
                switch (error.response.status) {
                    case 400:
                        navigate("/400")
                        break
                    case 401:
                        navigate("/login")
                        break
                    case 403:
                        navigate("/403")
                        break
                    case 404:
                        navigate('/404')
                        break
                    case 409:
                        navigate("/409")
                        break
                    case 422:
                        navigate('/422')
                        break
                    case 500:
                        navigate("/500")
                        break
                    default:
                        navigate("/500")
                        break
                }
            }

            const currentId = window.location.href.split('/')[window.location.href.split('/').length - 1]
            if (props.id === currentId) {
                navigate("/messenger")
            }

            await fetchMessages()
        }

        if (currentBox === "INBOX") {
            return (
                <div className="message-item mx-4 my-3 flex justify-center w-full">
                    <button className="rounded-l-2xl bg-base-200 h-16"
                            onClick={() => navigate(`/messenger/${props.id}`)}>
                        <div>
                            <h3>{props.sender.username}</h3>
                            <p>{subject}</p>
                        </div>
                    </button>
                    <button className="bg-primary rounded-r-2xl h-16 w-16 flex justify-center items-center"
                            onClick={deleteMessage}>
                        <IoMdTrash/>
                    </button>
                </div>
            )
        } else if (currentBox === "OUTBOX") {
            return (
                <div className="message-item mx-4 my-3 flex justify-center w-full">
                    <button className="message-item mx-4 my-3 bg-base-200"
                            onClick={() => navigate(`/messenger/${props.id}`)}>
                        <div>
                            <h3>{props.receiver.username}</h3>
                            <p>{subject}</p>
                        </div>
                    </button>
                    <button className="bg-primary rounded-r-2xl h-16 w-16 flex justify-center items-center"
                            onClick={deleteMessage}>
                        <IoMdTrash/>
                    </button>
                </div>
            )
        } else {
            return (
                <div className="message-item mx-4 my-3 flex justify-center w-full">
                    <button className="message-item mx-4 my-3 bg-base-200"
                            onClick={() => navigate(`/messenger/${props.id}`)}>
                        <div>
                            <p>{subject}</p>
                        </div>
                    </button>
                    <button className="bg-primary rounded-r-2xl h-16 w-16 flex justify-center items-center"
                            onClick={deleteMessage}>
                        <IoMdTrash/>
                    </button>
                </div>
            )
        }
    }


    return (
        <>
            {isLoading ? (
                <h3>
                    LOADING
                </h3>
            ) : (
                <ul>
                    {messages.map(msg => (
                        <MessageItem subject={msg.subject} key={msg._id} sender={msg.sender} receiver={msg.receiver}
                                     id={msg._id}/>
                    ))}
                </ul>
            )
            }
        </>
    )
}

export default MessageList