import {Link, useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import { FaHouseChimney } from "react-icons/fa6";

import AvatarPicture from "../components/AvatarPicture";
import axios from "axios";
import {FiAlertCircle} from "react-icons/fi";
import {AuthContext} from "../contexts/AuthContext";

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'

const Inscription = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const [formData, setFormData] = useState({
        username:"",
        email:"",
        password:"",
        password_confirm: "",
        dob: "",
        gender: "MALE",
        favoriteGame: "ODYSSEY",
        selectedPfp: "1"
    });

    const [formAvailability, setFormAvailability] = useState({
        username: true,
        email: true
    });

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Inscription | RetroRealm"

        if (authUser) {
            navigate('/home')
        }
    }, []);


    const {authUser, setAuthUser} = useContext(AuthContext)

    const handleSubmit = async (e) => {
        e.preventDefault();
        await signup()
    }


    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData, [name]: value
        });

        if (value !== "") {
            if (name === "username") {
                checkUsername(value)
            }
            if (name === "email") {
                checkEmail(value)
            }
        }
    };


    const checkUsername = async (username) => {
        try {
            const response = await axios.get(`${API_URL}/user/check-username/${username}`)
            setFormAvailability({
                ...formAvailability, username: response.data.isAvailable
            })
        } catch (error) {
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem("token")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate("/404")
                    break
                case 400:
                    const errors = error.response.data.errors
                    setFormErrors(errors)
                    break
                default:
                    navigate("/500")
            }
        }
    }
    const checkEmail = async (email) => {
        try {
            const response = await axios.get(`${API_URL}/user/check-email/${email}`)
            if (!response.data.error) {
                setFormAvailability({
                    ...formAvailability, email: response.data.isAvailable
                })
            }
        } catch (error) {
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem("token")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate("/404")
                    break
                case 400:
                    const errors = error.response.data.errors
                    setFormErrors(errors)
                    break
                default:
                    navigate("/500")
            }
        }
    }


    const signup = async () => {

        if (formAvailability.username || formAvailability.email) {
            return
        }

        const regExpString = '^[\'\"\-\$A-Za-zÀ-ÿ\ ]+$'
        const regExpEmail = '^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$'
        const regExpPassword = '^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$'

        const validationErrors = {};
        setFormErrors(validationErrors);

        if (!formData.username.trim()) {
            validationErrors.username = 'Ce champ est requis.';
        } else if (!formData.username.trim().match(regExpString)) {
            validationErrors.username = 'Le champ contient des caractères invalides.';
        } else if (formData.username.trim().length < 4) {
            validationErrors.username = "Le nom d'utilisateur doit contenir au moins 4 caractères.";
        }

        if (!formData.email.trim()) {
            validationErrors.email = 'Ce champ est requis.';
        } else if (!formData.email.trim().match(regExpEmail)) {
            validationErrors.email = 'L\'adresse courriel est invalide.';
        }

        if (!formData.password.trim()) {
            validationErrors.password = 'Ce champ est requis.';
        } else if (!formData.password.trim().match(regExpPassword)) {
            validationErrors.password = 'Le mot de passe est invalide. Il doit contenir au moins 8' +
                ' caractères dont au moins une lettre minuscule, une lettre majuscule et un chiffre.';
        }

        if (!formData.password_confirm.trim()) {
            validationErrors.password_confirm = 'Ce champ est requis.';
        } else if (!(formData.password_confirm.trim() === formData.password.trim())) {
            validationErrors.password_confirm = 'Les mots de passe ne correspondent pas.';
        }

        if (!formData.dob) {
            validationErrors.dob = 'Ce champ est requis.';
        } else {
            let today = new Date();
            let birthDate = new Date(formData.dob);
            let age = today.getFullYear() - birthDate.getFullYear();
            let m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }

            if (age < 18) {
                validationErrors.dob = "Vous devez avoir au moins 18 ans pour utiliser RetroRealm.";
            }
        }



        if (Object.keys(validationErrors).length === 0) {
            setIsLoading(true);

            let userData = {
                username: formData.username,
                email: formData.email,
                password: formData.password,
                password_confirm: formData.password_confirm,
                dob: formData.dob,
                gender: formData.gender,
                favoriteGame: formData.favoriteGame,
                selectedPfp: formData.selectedPfp
            };

            try {
                const response = await axios.post(`${API_URL}/auth/register`, userData);
                if (!response.data.error) {
                    localStorage.setItem('token', JSON.stringify(response.data.token));
                    setAuthUser(response.data.user)
                    navigate("/validate-email");
                }
            } catch (error) {
                switch (error.response.status) {
                    case 401:
                        localStorage.removeItem("token")
                        break
                    case 403:
                        navigate("/403")
                        break
                    case 404:
                        navigate("/404")
                        break
                    case 400:
                        const errors = error.response.data.errors
                        setFormErrors(errors)
                        break
                    default:
                        navigate("/500")
                }
            } finally {
                setIsLoading(false)
            }
        } else {
            setFormErrors(validationErrors)
            setIsLoading(false)
        }
    }



    return (
        <div className="h-screen flex justify-between bg-base-300">
            <div className="w-1/2 h-full bg-white flex flex-col justify-center ">
                <div className="p-20 overflow-auto">
                    <div className="flex justify-between items-baseline gap-4 mb-10">
                        <h2 className="text-base-300 text-5xl pixel-font">RetroRealm</h2>
                        <button onClick={() => {
                            navigate('/')
                        }}><FaHouseChimney size={36} className="text-base-300"/></button>
                    </div>
                    <form method="POST" onSubmit={handleSubmit} className="text-base-300">
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-base-300 text-lg">Nom d'utilisateur</span>
                            </div>
                            <input name="username" type="text" placeholder="Tapez ici" value={formData.username}
                                   onChange={handleChange}
                                   className="input input-se input-bordered w-full rounded-full bg-gray-200"/>

                            {formErrors.username && (
                                <div className="flex justify-start items-center gap-1">
                                    <FiAlertCircle size={24} className="text-red-500"/>
                                    <p className="text-red-500 text-lg">{formErrors.username}</p>
                                </div>
                            )}
                            {!formAvailability.username && (
                                <div className="flex justify-start items-center gap-1">
                                    <FiAlertCircle size={24} className="text-red-500"/>
                                    <p className="text-red-500 text-lg">Nom d'utilisateur déjà pris.</p>
                                </div>
                            )}
                        </label>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-base-300 text-lg">Adresse courriel</span>
                            </div>
                            <input name="email" type="email" placeholder="Tapez ici" value={formData.email}
                                   onChange={handleChange}
                                   className="input input-se input-bordered w-full rounded-full bg-gray-200"/>

                            {formErrors.email && (
                                <div className="flex justify-start items-center gap-1">
                                    <FiAlertCircle size={24} className="text-red-500"/>
                                    <p className="text-red-500 text-lg">{formErrors.email}</p>
                                </div>
                            )}
                            {!formAvailability.email && (
                                <div className="flex justify-start items-center gap-1">
                                    <FiAlertCircle size={24} className="text-red-500"/>
                                    <p className="text-red-500 text-lg">Adresse courriel déjà prise.</p>
                                </div>
                            )}
                        </label>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-base-300 text-lg">Mot de passe</span>
                            </div>
                            <input name="password" type="password" placeholder="Tapez ici"
                                   value={formData.password}
                                   onChange={handleChange}
                                   className="input input-se input-bordered w-full rounded-full bg-gray-200"/>

                            {formErrors.password && (
                                <div className="flex justify-start items-center gap-1">
                                    <FiAlertCircle size={24} className="text-red-500"/>
                                    <p className="text-red-500 text-lg">{formErrors.password}</p>
                                </div>
                            )}
                        </label>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-base-300 text-lg">Confirmation du mot de passe</span>
                            </div>
                            <input name="password_confirm" type="password" placeholder="Tapez ici"
                                   value={formData.password_confirm}
                                   onChange={handleChange}
                                   className="input input-se input-bordered w-full rounded-full bg-gray-200"/>

                            {formErrors.password_confirm && (
                                <div className="flex justify-start items-center gap-1">
                                    <FiAlertCircle size={24} className="text-red-500"/>
                                    <p className="text-red-500 text-lg">{formErrors.password_confirm}</p>
                                </div>
                            )}
                        </label>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-base-300 text-lg">Date de naissance</span>
                            </div>
                            <input name="dob" type="date" placeholder="Tapez ici" value={formData.dob}
                                   onChange={handleChange}
                                   className="input input-se input-bordered w-full rounded-full bg-gray-200"/>

                            {formErrors.dob && (
                                <div className="flex justify-start items-center gap-1">
                                    <FiAlertCircle size={24} className="text-red-500"/>
                                    <p className="text-red-500 text-lg">{formErrors.dob}</p>
                                </div>
                            )}
                        </label>

                        <label className="form-control w-full mt-8">
                            <div className="label">
                                <span className="label-text text-base-300 text-lg">Genre</span>
                            </div>
                        </label>
                        <div className="flex justify-around items-center mt-4">
                            <div className="flex items-center me-4">
                                <input id="gender-male" type="radio" value="MALE" name="gender"
                                       className="radio radio-primary"
                                       checked={formData.gender === "MALE"}
                                       onChange={handleChange}/>
                                <label htmlFor="gender-male"
                                       className="mx-2">Homme</label>
                            </div>
                            <div className="flex items-center me-4">
                                <input id="gender-female" type="radio" value="FEMALE" name="gender"
                                       className="radio radio-primary"
                                       checked={formData.gender === "FEMALE"}
                                       onChange={handleChange}/>
                                <label htmlFor="gender-female"
                                       className="mx-2">Femme</label>
                            </div>
                            <div className="flex items-center me-4">
                                <input id="gender-other" type="radio" value="OTHER" name="gender"
                                       className="radio radio-primary"
                                       checked={formData.gender === "OTHER"}
                                       onChange={handleChange}/>
                                <label htmlFor="gender-other"
                                       className="mx-2">Autre</label>
                            </div>
                        </div>
                        {formErrors.gender && (
                            <div className="flex justify-start items-center gap-1">
                                <FiAlertCircle size={24} className="text-red-500"/>
                                <p className="text-red-500 text-lg">{formErrors.gender}</p>
                            </div>
                        )}

                        <label className="form-control w-full mt-8">
                            <div className="label">
                                <span className="label-text text-base-300 text-lg">Jeu préféré</span>
                            </div>
                        </label>
                        <div className="flex justify-around items-center mt-4">
                            <div className="flex items-center me-4">
                                <input id="favorite-odyssey" type="radio" value="ODYSSEY" name="favoriteGame"
                                       className="radio radio-primary"
                                       checked={formData.favoriteGame === "ODYSSEY"}
                                       onChange={handleChange}/>
                                <label htmlFor="favorite-odyssey"
                                       className="mx-2">Odyssey</label>
                            </div>
                            <div className="flex items-center me-4">
                                <input id="favorite-allure" type="radio" value="ALLURE" name="favoriteGame"
                                       className="radio radio-primary"
                                       checked={formData.favoriteGame === "ALLURE"}
                                       onChange={handleChange}/>
                                <label htmlFor="favorite-allure"
                                       className="mx-2">À Vive Allure!</label>
                            </div>
                        </div>
                        {formErrors.favoriteGame && (
                            <div className="flex justify-start items-center gap-1">
                                <FiAlertCircle size={24} className="text-red-500"/>
                                <p className="text-red-500 text-lg">{formErrors.favoriteGame}</p>
                            </div>
                        )}

                        <label className="form-control w-full mt-8">
                            <div className="label">
                                <span className="label-text text-base-300 text-lg">Photo de profil</span>
                            </div>
                        </label>
                        <div className="flex justify-around items-center mb-8">

                            <label htmlFor="pfp-1"
                                   className="p-2">
                                <input type="radio" id="pfp-1" name="selectedPfp" value="1"
                                       className="hidden peer"
                                       checked={formData.selectedPfp === "1"}
                                       onChange={handleChange}/>
                                <AvatarPicture url={`${formData.username}1`}/>
                            </label>
                            <label htmlFor="pfp-2"
                                   className="p-2">
                                <input type="radio" id="pfp-2" name="selectedPfp" value="2"
                                       className="hidden peer"
                                       checked={formData.selectedPfp === "2"}
                                       onChange={handleChange}/>
                                <AvatarPicture url={`${formData.username}2`}/>
                            </label>
                            <label htmlFor="pfp-3"
                                   className="p-2">
                                <input type="radio" id="pfp-3" name="selectedPfp" value="3"
                                       className="hidden peer"
                                       checked={formData.selectedPfp === "3"}
                                       onChange={handleChange}/>
                                <AvatarPicture url={`${formData.username}3`}/>
                            </label>

                            <label htmlFor="pfp-4"
                                   className="p-2">
                                <input type="radio" id="pfp-4" name="selectedPfp" value="4"
                                       className="hidden peer"
                                       checked={formData.selectedPfp === "4"}
                                       onChange={handleChange}/>
                                <AvatarPicture url={`${formData.username}4`}/>
                            </label>

                        </div>
                        {formErrors.selectedPfp && (
                            <div className="flex justify-start items-center gap-1">
                                <FiAlertCircle size={24} className="text-red-500"/>
                                <p className="text-red-500 text-lg">{formErrors.selectedPfp}</p>
                            </div>
                        )}
                        {isLoading ? (
                            <button className="btn btn-primary rounded-full">
                                <span className="loading loading-spinner"></span>
                                Chargement
                            </button>
                        ) : (
                            <button type="submit" className="btn btn-primary rounded-full">S'inscrire</button>
                        )}
                    </form>
                    <div className="flex justify-center items-center px-20 gap-10 my-10">
                        <p className="text-black">Vous avez déjà un compte?</p>
                        <p className="text-black">|</p>
                        <button className="link link-primary block text-center" onClick={() => {
                            navigate('/login')
                        }}>Se connecter
                        </button>
                    </div>
                </div>
            </div>
            <div className="w-1/2 h-full login-landscape"></div>
        </div>
    )
}

export default Inscription;