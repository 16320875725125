import Header from "./Header"
import React, {useContext, useEffect, useState} from "react"
import Footer from "./Footer"
import {Link, useNavigate, useParams} from "react-router-dom"
import {IoMdArrowRoundBack, IoMdTrash} from "react-icons/io"
import {calculateAge} from "../utils/UtilMethods"
import {FaEdit} from "react-icons/fa"
import {AuthContext} from "../contexts/AuthContext"
import AvatarPicture from "./AvatarPicture"
import axios from "axios"

const MessageViewer = () => {
    const navigate = useNavigate()
    const {messageId} = useParams();
    const [isLoading, setIsLoading] = useState(true)
    const [message, setMessage] = useState({})

    useEffect(() => {
        // declare the data fetching function
        const fetchMessage = async () => {
            setIsLoading(true)
            let url;
            try {
                const API_URL = process.env.API_URL || "http://localhost:3001"
                url = `${API_URL}/message/getbyid/${messageId}`

                console.log("Fetching... " + url)
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                    }
                })
                console.dir(response.data)
                setMessage(response.data)
            } catch (error) {
                console.error(error)
                switch (error.response.status) {
                    case 400:
                        navigate("/messenger")
                        break
                    case 401:
                        navigate("/login")
                        break
                    case 403:
                        navigate("/messenger")
                        break
                    case 404:
                        navigate("/messenger")
                        break
                    case 409:
                        navigate("/messenger")
                        break
                    case 422:
                        navigate("/messenger")
                        break
                    case 500:
                        navigate("/500")
                        break
                    default:
                        navigate("/500")
                        break
                }
            } finally {
                setIsLoading(false)
            }
        }

        // call the function
        fetchMessage()
            // make sure to catch any error
            .catch(console.error);
    }, [messageId])




    return (
        <>
            {isLoading ? (
                <h3>LOADING</h3>
            ) : (
                <>
                    <h3>À: {message.receiver.username}</h3>
                    <h3>De: {message.sender.username}</h3>
                    <h3>{message.subject}</h3>
                    <p>{message.message}</p>
                </>
            )}
        </>
    )
}

export default MessageViewer