import {Link, useNavigate} from "react-router-dom";
import {IoMdArrowRoundBack, IoMdSearch} from "react-icons/io";
import React, {useContext, useEffect, useState} from "react";
import Header from "../components/Header"
import Footer from "../components/Footer"
import User from "../components/User"
import {calculateAge} from "../utils/UtilMethods";
import axios from "axios";
import useTreatFriendRequest from "../hooks/useTreatFriendRequest";
import {SocketContext} from "../contexts/SocketContext";

const SearchUser = () => {
    const navigate = useNavigate()
    const [users, setUsers] = useState([]);
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001';
    const [selectedGenders, setSelectedGenders] = useState(['MALE', 'FEMALE', 'OTHER']);
    const [selectedGames, setSelectedGames] = useState(['ODYSSEY', 'ALLURE']);
    const [filterAge, setFilterAge] = useState(false);
    const [filterGender, setFilterGender] = useState(false);
    const [filterGame, setFilterGame] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        ageMin: 18,
        ageMax: '',
        gender: selectedGenders,
        favoriteGame: selectedGames
    });
    const [errors, setErrors] = useState({ ageMin: '', ageMax: '' });
    const {socket} = useContext(SocketContext)
    const {friendRequests, setFriendRequests} = useTreatFriendRequest()

    useEffect(() => {
        document.title = "Recherche d'utilisateur | RetroRealm"
        fetchFriendRequests()
    }, [])

    useEffect(() => {
        socket?.on('friend-request', (data) => {
            setFriendRequests([...friendRequests, data.friendRequest])
        })

        return () => socket?.off('friend-request')
    }, [socket, setFriendRequests, friendRequests])

    const fetchFriendRequests = async () => {
        try {
            const API_URL = process.env.API_URL || 'http://localhost:3001'
            const response = await axios.get(API_URL + '/friend-request', {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 200) {
                setFriendRequests(response.data)
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        }
    }

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
        setErrors({age: ''})
    };

    const handleGenderCheckboxChange = (gender) => {
        const updatedGenders = selectedGenders.includes(gender)
            ? selectedGenders.filter((item) => item !== gender)
            : [...selectedGenders, gender];

        setSelectedGenders(updatedGenders);
        setFormData((prevData) => ({
            ...prevData,
            gender: updatedGenders
        }));
    };

    const handleGameCheckboxChange = (game) => {
        const updatedGames = selectedGames.includes(game)
            ? selectedGames.filter((item) => item !== game)
            : [...selectedGames, game];

        setSelectedGames(updatedGames);
        setFormData((prevData) => ({
            ...prevData,
            favoriteGame: updatedGames
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (filterAge && formData.ageMin && formData.ageMax && formData.ageMin > formData.ageMax) {
            newErrors.age = "Âge minimum doit être plus petit que l'âge maximum";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setUsers([]);
        if (!validateForm()) return;
        try {
            const adjustedFormData = { ...formData };
            if (!filterAge) {
                adjustedFormData.ageMin = 18;
                adjustedFormData.ageMax = '';
            }
            if (!filterGender) {
                adjustedFormData.gender = [];
            }
            if (!filterGame) {
                adjustedFormData.favoriteGame = [];
            }
            if (errors.age) {
                return
            }

            const response = await axios.post(`${API_URL}/user/search`, adjustedFormData, {
                headers: { "Authorization": `Bearer ${JSON.parse(localStorage.getItem("token"))}` }
            });
            setUsers(response.data.users || []);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <main className="flex-grow mx-auto max-w-7xl w-11/12">
                <div className="flex justify-between items-center mb-5">
                    <Link to="/home" className="rounded-full hover:bg-base-300 transition"><IoMdArrowRoundBack size={56}
                                                                                                               className="text-white"/></Link>
                    <h2 className="text-5xl">Recherche d'utilisateur</h2>
                    <p className="mr-14"></p>
                </div>
                <form onSubmit={handleSubmit} className="bg-base-300 rounded-2xl px-10 py-5 mb-5" noValidate>
                    <div className="flex items-center">
                        <div className="bg-base-100 rounded-2xl mb-5 px-10 py-5 w-full">
                            <h3>Nom d'utilisateur</h3>
                            <input
                                type="text"
                                name="username"
                                id="username"
                                placeholder="Entrez un nom"
                                value={formData.username}
                                onChange={handleChange}
                                className="input input-bordered w-full mt-4 mb-2 bg-neutral text-white"
                            />
                        </div>
                        <button type="submit"
                                className="bg-base-100 rounded-full p-5 ms-5 mb-5 text-white h-full hover:opacity-80 hover:scale-105 ease-in-out duration-300 delay-300">
                            <IoMdSearch size={64}/>
                        </button>
                    </div>
                    <div className="flex gap-20 justify-between">
                        <div className={`bg-base-100 rounded-2xl px-10 py-5 w-1/3 ${!filterAge ? 'bg-opacity-50' : ''}`}>
                            <div className="flex justify-between mb-6">
                                <h3>Âge</h3>
                                <div className="flex items-center opacity-100">
                                    <label htmlFor="filterAge"
                                           className="font-semibold text-white me-2">Filtrer</label>
                                    <input id="filterAge"
                                           type="checkbox"
                                           className="checkbox"
                                           checked={filterAge}
                                           onChange={() => setFilterAge(!filterAge)}
                                    />
                                </div>
                            </div>
                            <label htmlFor="ageMin" className={`block font-semibold text-white ${!filterAge ? 'opacity-50' : ''}`}>
                                Minimum
                            </label>
                            <input type="number"
                                   id="ageMin"
                                   name="ageMin"
                                   className={`input input-bordered w-full bg-neutral text-white my-2 ${!filterAge ? 'opacity-50' : ''}`}
                                   value={formData.ageMin}
                                   onChange={handleChange}
                                   disabled={!filterAge}
                            />
                            <label htmlFor="ageMax" className={`block font-semibold text-white ${!filterAge ? 'opacity-50' : ''}`}>
                                Maximum
                            </label>
                            <input type="number"
                                   id="ageMax"
                                   name="ageMax"
                                   className={`input input-bordered w-full bg-neutral text-white my-2 ${!filterAge ? 'opacity-50' : ''}`}
                                   value={formData.ageMax}
                                   onChange={handleChange}
                                   disabled={!filterAge}
                            />
                            {errors.age && <p className="text-red-500">{errors.age}</p>}
                        </div>
                        <div className={`bg-base-100 rounded-2xl px-10 py-5 w-1/3 ${!filterGender ? 'bg-opacity-50' : ''}`}>
                            <div className="flex justify-between mb-6">
                                <h3>Genre</h3>
                                <div className="flex items-center">
                                    <label htmlFor="filterGender"
                                           className="font-semibold text-white me-2">Filtrer</label>
                                    <input id="filterGender"
                                           type="checkbox"
                                           className="checkbox"
                                           checked={filterGender}
                                           onChange={() => setFilterGender(!filterGender)}
                                    />
                                </div>
                            </div>
                            <div className={`flex mb-6 ${!filterGender ? 'opacity-50' : ''}`}>
                                <input
                                    id="man"
                                    type="checkbox"
                                    className="checkbox me-2"
                                    checked={selectedGenders.includes('MALE')}
                                    onChange={() => handleGenderCheckboxChange('MALE')}
                                    disabled={!filterGender}
                                />
                                <label htmlFor="man" className="font-semibold text-white">Homme</label>
                            </div>
                            <div className={`flex mb-6 ${!filterGender ? 'opacity-50' : ''}`}>
                                <input
                                    id="woman"
                                    type="checkbox"
                                    className="checkbox me-2"
                                    checked={selectedGenders.includes('FEMALE')}
                                    onChange={() => handleGenderCheckboxChange('FEMALE')}
                                    disabled={!filterGender}
                                />
                                <label htmlFor="woman" className="font-semibold text-white">Femme</label>
                            </div>
                            <div className={`flex ${!filterGender ? 'opacity-50' : ''}`}>
                                <input
                                    id="other"
                                    type="checkbox"
                                    className="checkbox me-2"
                                    checked={selectedGenders.includes('OTHER')}
                                    onChange={() => handleGenderCheckboxChange('OTHER')}
                                    disabled={!filterGender}
                                />
                                <label htmlFor="other" className="font-semibold text-white">Autre</label>
                            </div>
                        </div>
                        <div className={`bg-base-100 rounded-2xl px-10 py-5 w-1/3 ${!filterGame ? 'bg-opacity-50' : ''}`}>
                            <div className="flex justify-between mb-6">
                                <h3>Jeu préféré</h3>
                                <div className="flex items-center opacity-100">
                                    <label htmlFor="filterGame"
                                           className="font-semibold text-white me-2">Filtrer</label>
                                    <input id="filterGame"
                                           type="checkbox"
                                           className="checkbox"
                                           checked={filterGame}
                                           onChange={() => setFilterGame(!filterGame)}
                                    />
                                </div>
                            </div>
                            <div className={`flex mb-6 ${!filterGame ? 'opacity-50' : ''}`}>
                                <input id="odyssey"
                                       type="checkbox"
                                       checked={selectedGames.includes('ODYSSEY')}
                                       className="checkbox me-2"
                                       onChange={() => handleGameCheckboxChange('ODYSSEY')}
                                       disabled={!filterGame}
                                />
                                <label htmlFor="odyssey" className="font-semibold text-white">Odyssey</label>
                            </div>
                            <div className={`flex ${!filterGame ? 'opacity-50' : ''}`}>
                                <input id="allure"
                                       type="checkbox"
                                       checked={selectedGames.includes('ALLURE')}
                                       className="checkbox me-2"
                                       onChange={() => handleGameCheckboxChange('ALLURE')}
                                       disabled={!filterGame}
                                />
                                <label htmlFor="allure" className="font-semibold text-white">À Vive Allure !</label>
                            </div>
                        </div>
                    </div>
                </form>
                <div className="bg-base-300 rounded-2xl px-10 py-5 mb-5">
                    <h3 className="mb-2">{users.length} résultat{users.length > 1 && ('s')}</h3>
                    <hr className="opacity-50 mb-4"/>
                    <ul>
                        {users.length > 0 && (
                            users.map((user) => (
                                <User key={user._id}
                                      id={user._id}
                                      username={user.username}
                                      pfpFileName={user.pfpFileName}
                                      gender={user.gender}
                                      age={calculateAge(user.dob)}
                                      favoriteGame={user.favoriteGame}
                                      />
                            ))
                        )}
                    </ul>
                </div>
            </main>
            <Footer/>
        </div>
    )
}

export default SearchUser