import Header from "./Header"
import React, {useContext, useEffect, useState} from "react"
import Footer from "./Footer"
import {Link, useNavigate} from "react-router-dom"
import {IoMdArrowRoundBack} from "react-icons/io"
import {calculateAge} from "../utils/UtilMethods"
import {FaEdit} from "react-icons/fa"
import {AuthContext} from "../contexts/AuthContext"
import AvatarPicture from "./AvatarPicture"
import axios from "axios"
import Select from "react-select"

const MessageWriter = () => {
    const navigate = useNavigate()

    const {authUser, setAuthUser} = useContext(AuthContext)
    const [formData, setFormData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [showSubmit, setShowSubmit] = useState(true)

    const [friendList, setFriendList] = useState([])

    useEffect(() => {
        setFriendList([])
        console.dir(authUser.friends)
        authUser.friends.forEach((friend) => {
            const friendToAdd = {
                value: friend._id,
                label: friend.username
            }
            const newFriendList = friendList
            newFriendList.push(friendToAdd)
            setFriendList(newFriendList)
        })
    }, [])

    const handleChange = (e) => {
        // Si le changement viens du select ("e" contiens le label et la valeur)
        if (typeof e.target === "undefined" ) {
            setFormData({...formData, receiver: e.value})
        } else {
            const {name, value} = e.target
            setFormData({...formData, [name]: value})
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            setIsLoading(true)
            setShowSubmit(false)
            console.log(formData)
            const API_URL = process.env.API_URL || "http://localhost:3001"
            const response = await axios.post( `${API_URL}/message`, formData, {
                headers: {
                    'Authorization': `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })

            if (response.status === 201) {
                setShowToast(true)
                setTimeout(() => {
                    setShowToast(false)
                }, [5000])
                navigate("/messenger")
            }
        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/login")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    navigate('/404')
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    navigate('/422')
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <form onSubmit={handleSubmit} noValidate>
            <label className="form-control w-full my-5">

                        <Select options={friendList} onChange={handleChange}/>
            </label>
            <label className="form-control w-full my-5">
                <input name="subject" type="text" placeholder="Sujet" value={formData.subject}
                       onChange={handleChange}
                       className="input input-se input-bordered w-full rounded-full bg-gray-200 text-black"/>
            </label>
            <label className="form-control w-full my-5">

                        <textarea name="message" placeholder="Message" value={formData.message}
                                  onChange={handleChange}
                                  className="input input-se input-bordered w-full h-64 rounded-3xl bg-gray-200 text-black"/>
            </label>

            {showSubmit && (
                <>
                    {isLoading ? (
                        <button className="btn btn-primary rounded-full w-44 mt-8">
                            <span className="loading loading-spinner"></span>
                            Chargement
                        </button>
                    ) : (
                        <button type="submit"
                                className="btn btn-primary rounded-full w-44 mt-8">
                            Envoyer
                        </button>
                    )}
                </>
            )}

        </form>
    )
}

export default MessageWriter