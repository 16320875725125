import {useNavigate, useOutletContext} from "react-router-dom"
import React, {useCallback, useContext, useEffect, useState} from "react"
import {AuthContext} from "../contexts/AuthContext"
import {MdDeleteForever} from "react-icons/md"
import {IoPersonAdd, IoPersonRemove} from "react-icons/io5"
import {SocketContext} from "../contexts/SocketContext"
import axios from "axios"
import useTreatFriendRequest from "../hooks/useTreatFriendRequest"


const ProfileForeignFriends = () => {
    const navigate = useNavigate()

    const {authUser} = useContext(AuthContext)
    const {socket, onlineUsers} = useContext(SocketContext)
    const user = useOutletContext()
    const {sendFriendRequest, deleteFriendRequest, friendRequests, setFriendRequests} = useTreatFriendRequest()

    const [toastMessage, setToastMessage] = useState("")
    const [showToast, setShowToast] = useState(false)
    const [isActionLoading, setIsActionLoading] = useState(false)

    const handleCreateClick = async (senderId, receiverId) => {
        setIsActionLoading(true)
        const receivedFriendRequest = await sendFriendRequest(senderId, receiverId)
        setFriendRequests([...friendRequests, receivedFriendRequest])
        setToastMessage("Demande d'ami envoyée")
        setShowToast(true)
        setTimeout(() => {
            setShowToast(false)
        }, [3000])
        setIsActionLoading(false)
    }

    const handleDeleteClick = async (senderId, receiverId) => {
        setIsActionLoading(true)
        const isDeleted = await deleteFriendRequest(senderId, receiverId)
        if (isDeleted) {
            setFriendRequests(friendRequests.filter(friendRequest => friendRequest.sender !== senderId && friendRequest.receiver !== receiverId))
            setToastMessage("Demande d'ami annulée")
            setShowToast(true)
            setTimeout(() => {
                setShowToast(false)
            }, [3000])
        }
        setIsActionLoading(false)
    }

    return (
        <div className="bg-base-300 rounded-2xl px-10 py-5 mb-5">
            <h2 className="text-3xl mb-4">Amis de {user.username}</h2>
            {user.friends && user.friends.length > 0 ? (
                user.friends.map((friend, i) => (
                    <div
                        className="bg-base-200 hover:bg-base-100 transition p-5 flex justify-between items-center mb-3 rounded-2xl hover:cursor-pointer"
                        onClick={() => {navigate(`/profile/${friend._id}`)}}
                        key={i}>
                        <div className="flex justify-start items-center gap-5">
                            <div className={`avatar ${onlineUsers.includes(friend._id) ? 'online' : 'offline'}`}>
                                <div className="ring-primary ring-offset-base-100 w-12 rounded-full ring ring-offset-2">
                                    <img className="rounded-full"
                                         src={`https://robohash.org/${friend.pfpFileName}?set=set2`}
                                         alt="Photo de profil"/>
                                </div>
                            </div>

                            <h3>{friend.username}</h3>
                        </div>
                        {friend._id !== authUser._id && (
                            <>
                                {(!authUser.friends.some(authFriend => authFriend._id === friend._id) &&
                                    !friendRequests.some(friendRequest => friendRequest.sender === authUser._id &&
                                        friendRequest.receiver === friend._id) &&
                                    !friendRequests.some(friendRequest => friendRequest.sender === friend._id &&
                                        friendRequest.receiver === authUser._id)) ? (
                                    <>
                                        {isActionLoading ? (
                                            <span className="loading loading-spinner loading-lg"></span>
                                        ) : (
                                            <button onClick={(e) => {
                                                e.stopPropagation()
                                                handleCreateClick(authUser._id, friend._id)
                                            }}>
                                                <IoPersonAdd size={32} className="text-white"/>
                                            </button>
                                        )}
                                    </>
                                ) : (!authUser.friends.some(authFriend => authFriend._id === friend._id) &&
                                    friendRequests.some(friendRequest => friendRequest.sender === authUser._id &&
                                        friendRequest.receiver === friend._id)) ? (
                                    <>
                                        {isActionLoading ? (
                                            <span className="loading loading-spinner loading-lg"></span>
                                        ) : (
                                            <button onClick={() => {
                                                handleDeleteClick(authUser._id, friend._id)
                                            }}>
                                                <IoPersonRemove size={32} className="text-white"/>
                                            </button>
                                        )}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </>
                        )}

                    </div>
                ))
            ) : (
                <p className="text-center text-xl">{authUser.username} n'a pas encore d'amis</p>
            )}

            <div className={`toast toast-top toast-center ${showToast ? "block" : "hidden"}`}>
                <div className="alert alert-success">
                    <span>{toastMessage}</span>
                </div>
            </div>
        </div>
    )
}

export default ProfileForeignFriends