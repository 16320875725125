import {createContext, useContext, useEffect, useState} from "react"
import {AuthContext} from "./AuthContext"
import {io} from "socket.io-client"


export const SocketContext = createContext()

export const SocketContextProvider = ({children}) => {
    const [socket, setSocket] = useState(null)
    const [onlineUsers, setOnlineUsers] = useState([])
    const {authUser} = useContext(AuthContext)

    useEffect(() => {
        if (authUser) {
            const uri = process.env.API_URL || 'http://localhost:3001'
            const socket = io(uri, {
                query: {
                    userId: authUser._id
                }
            })
            setSocket(socket)

            socket.on('getOnlineUsers', (users) => {
                setOnlineUsers(users)
            })

            return () => {
                socket.close()
            }
        } else {
            if (socket) {
                socket.close()
                setSocket(null)
            }
        }
    }, [authUser])

    return (
        <SocketContext.Provider value={{socket, onlineUsers}}>{children}</SocketContext.Provider>
    )
}