import PresentationHeader from "../components/PresentationHeader"
import Footer from "../components/Footer"
import {useEffect, useState} from "react"
import {Link, useNavigate} from "react-router-dom"


const Error = ({code}) => {
    const navigate = useNavigate()

    const [message, setMessage] = useState("")
    const [title, setTitle] = useState("")

    useEffect(() => {
        switch (code) {
            case 400:
                setTitle("Mauvaise requête")
                setMessage("La requête n'a pu être traitée dû à une erreur du côté client.")
                break
            case 401:
                setTitle("Non autorisé")
                setMessage("Vous devez être authentifié pour accéder à cette ressource.")
                break
            case 403:
                setTitle("Accès refusé")
                setMessage("Vous ne disposez pas des droits nécessaires pour accéder à cette ressource.")
                break
            case 404:
                setTitle("Ressource introuvable")
                setMessage("La ressource demandée est introuvable.")
                break
            case 409:
                setTitle("Conflit")
                setMessage("La réponse du serveur présente un conflit avec l'état actuel du serveur.")
                break
            case 422:
                setTitle("Contenu non traitable")
                setMessage("La requête est valide, mais son contenu est non traitable")
                break
            case 500:
                setTitle("Erreur serveur")
                setMessage("Une erreur s'est produite du côté du serveur.")
                break
            default:
                navigate('/500')
                break
        }
    }, [])

    useEffect(() => {
        document.title = `Erreur ${code} - ${title}`
    }, [title])

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <PresentationHeader />
            <div className="flex-grow flex justify-center items-center">
                <div className="rounded-3xl bg-base-300 w-1/3 p-10 flex flex-col justify-center items-center">
                    <h1 className="text-8xl mb-4 text-center">{code}</h1>
                    <h2 className="text-center">{title}</h2>
                    <p className="mb-4 text-lg text-center">{message}</p>
                    <Link to="/home" className="btn btn-primary rounded-full text-lg">Accueil</Link>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Error