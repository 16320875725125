import React, {useContext, useEffect} from "react"
import {calculateAge, displayFavouriteGame, displayGender} from "../utils/UtilMethods"
import {AuthContext} from "../contexts/AuthContext"
import {useOutletContext} from "react-router-dom"


const ProfileOverview = () => {
    const user = useOutletContext()

    return (
        <div className="bg-base-300 rounded-2xl px-10 py-5 mb-5">
            <h3 className="text-3xl mb-2">Bio</h3>
            <p className="mb-5 text-xl">{user.bio ? user.bio : "Aucune biographie de profil"}</p>

            <h3 className="text-3xl mb-2">Sexe</h3>
            <p className="mb-5 text-xl">{displayGender(user.gender)}</p>

            <h3 className="text-3xl mb-2">Âge</h3>
            <p className="mb-5 text-xl">{calculateAge(user.dob)} ans</p>

            <h3 className="text-3xl mb-2">Jeu préféré</h3>
            <p className="mb-5 text-xl">{displayFavouriteGame(user.favoriteGame)}</p>
        </div>
    )
}

export default ProfileOverview