import React, {useContext} from "react";
import {FaLock} from "react-icons/fa";
import {AuthContext} from "../contexts/AuthContext";
import {useNavigate} from "react-router-dom";

const BannedModal = () => {
    const navigate = useNavigate()

    const {authUser, setAuthUser} = useContext(AuthContext)

    const handleLogout = async () => {
        localStorage.removeItem('token')
        setAuthUser(null)
        navigate('/login')
    }

    return (
        <>
            <dialog open id="banned-modal" className="modal">
                <section className="modal-box bg-gray-900">
                    <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                        <div className="mx-auto max-w-screen-sm text-center">
                                <FaLock className="mx-auto mb-4 text-7xl tracking-tight font-extrabold lg:text-9xl text-primary-600 text-primary-500"/>
                            {authUser.isBanned &&
                                <p className="mb-4 text-3xl tracking-tight font-bold md:text-4xl text-white">Vous avez
                                    été bannis</p>
                            }

                            {authUser.suspendedUntil &&
                                <>
                                    <p className="mb-4 text-3xl tracking-tight font-bold md:text-4xl text-white">Vous
                                        avez été suspendu</p>
                                    <p className="mb-4 text-lg font-light text-gray-400">Votre compte sera utilisable
                                        au {new Intl.DateTimeFormat('fr-ca', {
                                            dateStyle: 'long',
                                            timeStyle: 'short'
                                        }).format(new Date(authUser.suspendedUntil))}.</p>
                                </>
                            }

                            <button onClick={handleLogout} className="inline-flex text-white bg-primary-600  hover:ring-1 hover:outline-none hover:ring-white font-medium rounded-lg text-sm px-5 py-2.5 text-center my-4">
                                Se déconnecter</button>
                        </div>
                    </div>
                </section>
            </dialog>
        </>
    )
}

export default BannedModal