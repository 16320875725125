import {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router-dom"
import { FaHouseChimney } from "react-icons/fa6"
import axios from "axios"
import {AuthContext} from "../contexts/AuthContext"
import {FiAlertCircle} from "react-icons/fi"
import error from "./Error"

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3001'

const Login = () => {
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    })

    const [errors, setErrors] = useState({})
    const {authUser, setAuthUser} = useContext(AuthContext)

    useEffect(() => {
        document.title = "Connexion | RetroRealm"

        if (authUser) {
            navigate('/home')
        }
    }, [authUser])

    const handleChange = (e) => {
        const {name, value} = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const receivedErrors = validateSubmit(formData)
        if (Object.keys(receivedErrors).length > 0) {
            setErrors(receivedErrors)
            return
        }

        try {
            setIsLoading(true)
            const url = `${API_URL}/auth/login`
            const response = await axios.post(url, formData)

            localStorage.setItem('token', JSON.stringify(response.data.token))
            setAuthUser(response.data.user)

            if (!response.data.user.isAccountValidated) {
                navigate('/validate-email')
            } else {
                navigate('/home')
            }

        } catch (error) {
            console.error(error)
            switch (error.response.status) {
                case 400:
                    navigate("/400")
                    break
                case 401:
                    navigate("/401")
                    break
                case 403:
                    navigate("/403")
                    break
                case 404:
                    setErrors({
                        username: "Nom d'utilisateur ou mot de passe incorrect.",
                        password: "Nom d'utilisateur ou mot de passe incorrect."
                    })
                    break
                case 409:
                    navigate("/409")
                    break
                case 422:
                    setErrors({
                        username: "Nom d'utilisateur ou mot de passe incorrect.",
                        password: "Nom d'utilisateur ou mot de passe incorrect."
                    })
                    break
                case 500:
                    navigate("/500")
                    break
                default:
                    navigate("/500")
                    break
            }
        } finally {
            setIsLoading(false)
        }
    }

    return (
        <div className="h-screen flex justify-between">
            <div className="w-1/2 h-full bg-white flex flex-col justify-center">
                <div className="p-20 overflow-auto">
                    <div className="flex justify-between items-baseline gap-4 mb-10">
                        <h2 className="text-5xl text-black pixel-font">RetroRealm</h2>
                        <button onClick={() => {
                            navigate('/')
                        }}><FaHouseChimney size={36} className="text-black"/></button>
                    </div>
                    <form method="POST" onSubmit={handleSubmit}>
                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-black text-lg">Nom d'utilisateur</span>
                            </div>
                            <input type="text" placeholder="Tapez ici" name="username" value={formData.username}
                                   onChange={handleChange}
                                   className="input bg-gray-200 text-black input-bordered w-full rounded-full"/>
                        </label>
                        {errors.username && (
                            <div className="flex justify-start items-center gap-1">
                                <FiAlertCircle size={24} className="text-red-500"/>
                                <p className="text-red-500 text-lg">{errors.username}</p>
                            </div>
                        )}

                        <label className="form-control w-full">
                            <div className="label">
                                <span className="label-text text-black text-lg">Mot de passe</span>
                            </div>
                            <input type="password" placeholder="Tapez ici" name="password" value={formData.password}
                                   onChange={handleChange}
                                   className="input bg-gray-200 text-black input-bordered w-full rounded-full"/>
                        </label>
                        {errors.password && (
                            <div className="flex justify-start items-center gap-1">
                                <FiAlertCircle size={24} className="text-red-500"/>
                                <p className="text-red-500 text-lg">{errors.password}</p>
                            </div>
                        )}

                        <div className="flex justify-between mt-5">
                            {isLoading ? (
                                <button className="btn btn-primary rounded-full">
                                    <span className="loading loading-spinner"></span>
                                    Chargement
                                </button>
                            ) : (
                                <button type="submit" className="btn btn-primary rounded-full">Se connecter</button>
                            )}

                            <div>
                            <button className="link link-primary block text-right" onClick={() => {
                                    navigate('/forgotten-password')
                                }}>Mot de passe oublié</button>
                                <button className="link link-primary block text-right" onClick={() => {
                                    navigate('/contact')
                                }}>Nous contacter</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="flex justify-center items-center px-20 gap-10 my-10">
                    <p className="text-black">Vous n'avez pas de compte?</p>
                    <p className="text-black">|</p>
                    <button className="link link-primary block text-center" onClick={() => {
                        navigate('/register')
                    }}>Créer un compte</button>
                </div>
            </div>
            <div className="w-1/2 h-full login-landscape"></div>
        </div>
    )
}

export default Login

const validateSubmit = (formData) => {
    const errors = {}

    if (!formData.username) {
        errors.username = "Ce champ est requis"
    }

    if (!formData.password) {
        errors.password = "Ce champ est requis"
    }

    return errors
}