export const formatTimeAgo = (createdAt) => {
    const now = new Date();
    const createdAtDate = new Date(createdAt);
    const diffInSeconds = Math.floor((now - createdAtDate) / 1000);

    if (diffInSeconds < 60) {
        if (diffInSeconds === 1)
            return `${diffInSeconds} seconde`;
        return `${diffInSeconds} secondes`;
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
        if (diffInMinutes === 1)
            return `${diffInMinutes} minute`;
        return `${diffInMinutes} minutes`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
        if (diffInHours === 1)
            return `${diffInHours} heure`;
        return `${diffInHours} heures`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
        if (diffInDays === 1)
            return `${diffInDays} jour`;
        return `${diffInDays} jours`;
    }

    const diffInWeeks = Math.floor(diffInDays / 7);
    if (diffInWeeks < 4) {
        if (diffInWeeks === 1)
            return `${diffInWeeks} semaine`;
        return `${diffInWeeks} semaines`;
    }

    const diffInMonths = Math.floor(diffInDays / 30); // Approximate month
    if (diffInMonths < 12) {
        return `${diffInMonths} mois`;
    }

    const diffInYears = Math.floor(diffInMonths / 12);
    if (diffInYears === 1)
        return `${diffInYears} an`;
    return `${diffInYears} ans`;
};