export const calculateAge = (dob) => {
    const dateDob = new Date(dob)

    const month_diff = Date.now() - dateDob.getTime()
    const age_dt = new Date(month_diff)
    const year = age_dt.getUTCFullYear()

    return Math.abs(year - 1970)
}

export const displayFavouriteGame = (favouriteGame) => {
    switch (favouriteGame) {
        case 'ODYSSEY':
            return "Odyssey"
        case 'ALLURE':
            return "À vive allure!"
        default:
            return "Autre"
    }
}

export const displayGender = (gender) => {
    switch (gender) {
        case 'MALE':
            return "Homme"
        case 'FEMALE':
            return "Femme"
        default:
            return "Autre"
    }
}
