import Header from "../components/Header"
import Footer from "../components/Footer"
import {Link, Outlet, useNavigate} from "react-router-dom"
import {useContext, useEffect, useState} from "react"
import {AuthContext} from "../contexts/AuthContext"
import MessageWriter from "../components/MessageWriter";
import {MdMoveToInbox, MdOutbox} from "react-icons/md";
import {IoAdd} from "react-icons/io5";
import MessageList from "../components/MessageList";
import {IoMdTrash} from "react-icons/io";

const Messenger = () => {
    const navigate = useNavigate()
    const {authUser} = useContext(AuthContext)

    const [currentBox, setCurrentBox] = useState("INBOX")

    useEffect(() => {
        document.title = "Messagerie | RetroRealm"
    }, [authUser, navigate])

    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div className="flex-grow mx-auto max-w-7xl w-11/12">
                <div className="flex justify-between items-center mb-5">
                    <section className={"w-1/4 bg-base-300 rounded-2xl h-full mr-5 px-1 py-2"}>
                        <div className="flex items-center mb-2">
                            <button onClick={() => navigate("/messenger/new")}
                                    className="btn btn-primary rounded-full w-32 mt-8">
                                <IoAdd/>
                            </button>
                            <button onClick={() => {setCurrentBox("INBOX")}} className="mx-4">
                                <MdMoveToInbox/>
                            </button>
                            <button onClick={() => {setCurrentBox("OUTBOX")}} className="mx-4">
                                <MdOutbox/>
                            </button>
                            <button onClick={() => {setCurrentBox("TRASHED")}} className="mx-4">
                                <IoMdTrash />
                            </button>
                        </div>
                        <MessageList box={currentBox}/>

                    </section>
                    <section className={"w-3/4 bg-base-200 rounded-2xl h-96 ml-5  px-1 py-2"}>
                        <Outlet/>
                    </section>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Messenger