import React, {useContext, useEffect, useState} from "react"
import Footer from "../components/Footer";
import axios from "axios";

import {AuthContext} from "../contexts/AuthContext";
import {Link, useNavigate} from "react-router-dom";
import Header from "../components/Header";
import Reports from "../components/Reports";
import {IoMdArrowRoundBack} from "react-icons/io";

const ControlPanel = () => {
    const navigate = useNavigate()
    const {authUser, setAuthUser} = useContext(AuthContext)

    useEffect(() => {
        document.title = "Panneau de contrôle | RetroRealm"

        if (authUser.userType === "STANDARD") {
            navigate("/403")
        }
        getUsers()
    }, [])



    const cardsByPage = 10

    useEffect( () => {
        console.dir(authUser)
    }, [])

    const [formData, setFormData] = useState({
        text: ""
    })

    const [users, setUsers] = useState([])

    const [suspendFormData, setSuspendFormData] = useState({
        time: 3
    })

    const [currentPage, setCurrentPage] = useState(0)

    const getUsers = async () => {
        const url = process.env.API_URL || "http://localhost:3001" + '/user'
        console.log(url)
        const response = await axios.get(url, {
            headers: {
                "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
            }
        })
        const text = formData.text.trim().toUpperCase()
        setUsers(response.data.filter(x => !x.isDeleted && x.username.toUpperCase().includes(text)))
    }

    const handleChange = async e => {
        const {name, value} = e.target
        setFormData({
            ...formData,
            [name]: value
        })

        await getUsers()
        setCurrentPage(0)
    }

    const changeSupendFormData = e => {
        const {value} = e.target
        setSuspendFormData({
            ...suspendFormData,
            time: parseInt(value)
        })
    }

    const promote = async id => {
        const url = `${process.env.API_URL || "http://localhost:3001"}/user/${id}/promote`
        try {
            await axios.patch(url, {}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            await getUsers()
        } catch (error) {
            navigate(`/${error.response.status}`)
        }
    }

    const demote = async id => {
        const url = `${process.env.API_URL || "http://localhost:3001"}/user/${id}/demote`
        try {
            await axios.patch(url, {}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            await getUsers()
        } catch (error) {
            navigate(`/${error.response.status}`)
        }
    }

    const suspend = id => {
        document.getElementById('my_modal_3xx').showModal()
        setSuspendFormData({
            user: users.find(x => x._id = id),
            time: 3
        })
    }

    const unsuspend = async id => {
        const url = `${process.env.API_URL || "http://localhost:3001"}/user/${id}/unsuspend`
        try {
            await axios.patch(url,{}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            await getUsers()
        } catch (error) {
            navigate(`/${error.response.status}`)
        }
    }

    const ban = async id => {
        const url = `${process.env.API_URL || "http://localhost:3001"}/user/${id}/ban`
        try {
            await axios.patch(url,{}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            await getUsers()
        } catch (error) {
            navigate(`/${error.response.status}`)
        }
    }

    const unban = async id => {
        const url = `${process.env.API_URL || "http://localhost:3001"}/user/${id}/unban`
        try {
            await axios.patch(url, {}, {
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            await getUsers()
        } catch (error) {
            navigate(`/${error.response.status}`)
        }
    }

    const confirm_dialog = async e => {
        e.preventDefault()

        const now = new Date()
        const hours = suspendFormData.time * 60 * 60 * 1000
        const date = now.setTime(now.getTime() + hours)
        const url = `${process.env.API_URL || "http://localhost:3001"}/user/${suspendFormData.user._id}/suspend`

        try {
            await axios.patch(url, {suspendedUntil: date},{
                headers: {
                    "Authorization": `Bearer ${JSON.parse(localStorage.getItem('token'))}`
                }
            })
            await getUsers()
        } catch (error) {
            navigate(`/${error.response.status}`)
        }

        close_dialog()
    }

    const close_dialog = () => {
        document.getElementById('my_modal_3xx').close()
    }

    const getUserOptions = user => {
        if (user.userType === 'ADMIN')
            return

        if (user._id === authUser._id) {
            return <p>Moi</p>
        }

        if (user.isBanned)
            return (
                <div className="gap-3 flex">
                    <span className="text-red-500 inline-block align-middle">{user.username} a été banni</span>
                    <button className="btn btn-sm btn-error" onClick={() => unban(user._id)}>
                        Annuler
                    </button>
                </div>
            )

        if (user.suspendedUntil)
            return (
                <div className="gap-3 flex">
                    <p className="text-amber-400">{user.username} a été suspendu
                        jusqu'au {new Intl.DateTimeFormat('fr-ca', {
                            dateStyle: 'long',
                            timeStyle: 'short'
                        }).format(new Date(user.suspendedUntil))}</p>

                    <button className="btn btn-sm btn-error" onClick={() => unsuspend(user._id)}>
                        Annuler
                    </button>
                </div>

            )

        return (
            <div className="gap-3 flex">
                {authUser?.userType === 'ADMIN' && user?.userType === 'STANDARD' &&
                    <button className="btn btn-sm btn-success" onClick={() => promote(user._id)}>
                        Promouvoir
                    </button>
                }

                {authUser?.userType === 'ADMIN' && user?.userType === 'MOD' &&
                    <button className="btn btn-sm btn-success" onClick={() => demote(user._id)}>
                        Rétrograder
                    </button>
                }

                <button className="btn btn-sm btn-warning" onClick={() => suspend(user._id)}>
                    Suspendre
                </button>
                <button className="btn btn-sm btn-error" onClick={() => ban(user._id)}>
                    Bannir
                </button>
            </div>
        )
    }

    const getUserSearch = () => {
        return (
            <div role="tabpanel" className="tab-content p-10">
                <div className="m-auto max-w-3xl p-6 border rounded-lg shadow bg-gray-800 border-gray-700 mt-7">
                    <form className="mb-6">
                        <div className="relative">
                            <div
                                className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                <svg className="w-4 h-4 text-gray-400" aria-hidden="true"
                                     xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                          strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
                                </svg>
                            </div>
                            <input type="search" name="text" value={formData.text} onChange={handleChange}
                                   className="block w-full p-4 ps-10 text-sm border rounded-lg border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                                   placeholder="Rechercher un utilisateur"/>
                        </div>
                    </form>

                    <p className="mb-3">{users.length} résultats</p>

                    <ul className="menu bg-base-200 rounded-box w-full mb-3">
                        {users.slice(currentPage * cardsByPage, (currentPage + 1) * cardsByPage).map((user) => (
                            <li key={user._id}>
                                <div className="flex flex-row justify-between">
                                    <a href={"/profile/" + user._id}>
                                        <div className="flex items-center gap-3">
                                            <div className="avatar">
                                                <div className="mask mask-squircle h-12 w-12">
                                                    <img
                                                        src={`http://robohash.org/${user.pfpFileName}?set=set2`}
                                                        alt={user.username}/>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="font-bold">{user.username}</div>
                                                <div className="text-sm opacity-50">{user.userType}</div>
                                            </div>
                                        </div>
                                    </a>
                                    {getUserOptions(user)}
                                </div>

                            </li>
                        ))}
                    </ul>

                    <div className="join w-full justify-center">
                        {[...Array(Math.ceil(users.length / cardsByPage)).keys()].map((i) => (
                            <button key={i} className={"join-item btn " + (currentPage === i ? "btn-active" : "")}
                                    onClick={() => setCurrentPage(i)}>
                                {i + 1}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Header />
            <section className="container">
                <div className="flex justify-between items-center mb-5">
                    <Link to="/home" className="rounded-full hover:bg-base-300 transition"><IoMdArrowRoundBack size={56}
                                                                                                               className="text-white"/></Link>
                    <h2 className="text-5xl">Panneau de contrôle</h2>
                    <p className="mr-14"></p>
                </div>

                <div role="tablist" className="tabs tabs-bordered tabs-lg">
                    <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="Rechercher"
                           defaultChecked/>
                    {getUserSearch()}

                    <input type="radio" name="my_tabs_1" role="tab" className="tab" aria-label="Signalements"/>
                    <div role="tabpanel" className="tab-content p-10">
                        <Reports />
                    </div>
                </div>
            </section>

            <dialog id="my_modal_3xx" className="modal modal-bottom sm:modal-middle">
                <form className="modal-box" method="post" onSubmit={confirm_dialog}>
                    <h3 className="font-bold text-lg">{"Suspendre " + suspendFormData.user?.username}</h3>

                    <label htmlFor="countries" className="block mb-2 text-sm font-medium text-white">Durée de la
                        suspension</label>
                    <select name="time" value={suspendFormData.time} onChange={changeSupendFormData}
                            className="border text-sm rounded-lg block w-full p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500">
                        <option value={3}>3 heures</option>
                        <option value={12}>12 heures</option>
                        <option value={24}>24 heures</option>
                        <option value={72}>3 jours</option>
                    </select>

                    <div className="modal-action">
                        <div className="w-full grid sm:grid-cols-2 gap-3">
                            <button type="submit" className="btn btn-sm btn-success"
                                    onClick={confirm_dialog}>Confirmer
                            </button>
                            <button type="reset" className="btn btn-sm btn-error" onClick={close_dialog}>Annuler
                            </button>
                        </div>
                    </div>
                </form>
            </dialog>

            <Footer/>
        </>
    )
}

export default ControlPanel