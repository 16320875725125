import {Link, Outlet, useNavigate} from "react-router-dom"
import Header from "../components/Header"
import Footer from "../components/Footer"
import React, {useContext, useEffect} from "react"
import {IoMdArrowRoundBack} from "react-icons/io"
import {AuthContext} from "../contexts/AuthContext"
import {IoMdSettings} from "react-icons/io"
import {calculateAge} from "../utils/UtilMethods"


const ProfileAuth = () => {
    const navigate = useNavigate()

    const {authUser} = useContext(AuthContext)

    useEffect(() => {
        document.title = "Profil | RetroRealm"
    }, [])


    return (
        <div className="min-h-screen flex flex-col justify-between">
            <Header/>
            <div className="flex-grow mx-auto max-w-7xl w-11/12">
                <div className="flex justify-between items-center mb-5">
                    <Link to="/home" className="rounded-full hover:bg-base-300 transition"><IoMdArrowRoundBack size={56} className="text-white" /></Link>
                    <h2 className="text-5xl">Profil</h2>
                    <p className="mr-14"></p>
                </div>
                <div className="bg-base-300 rounded-2xl px-10 py-5 flex justify-between items-center mb-5">
                    <div className="flex gap-6 justify-start items-center">
                        <div className="ring-primary ring-offset-base-100 w-16 rounded-full ring ring-offset-2">
                            <img className="rounded-full" src={`https://robohash.org/${authUser.pfpFileName}?set=set2`} alt="Photo de profil"/>
                        </div>
                        <div>
                            <h3 className="text-3xl">{authUser.username}</h3>
                            <p className="text-xl">{calculateAge(authUser.dob)} ans</p>
                        </div>
                    </div>

                    <Link to="/profile-edit" className="rounded-full hover:bg-base-300 transition"><IoMdSettings size={32}
                                                                                                           className="text-white"/></Link>
                </div>

                <div className="flex justify-around items-center mb-5">
                    <Link to="/profile" className={`p-3 ${window.location.pathname === '/profile' ? "border-white" : "border-base-100"} hover:border-white border-b-2 transition text-xl text-white text-center w-32`}>
                        Aperçu
                    </Link>

                    <Link to="/profile/friends" className={`p-3 ${window.location.pathname === '/profile/friends' ? "border-white" : "border-base-100"} hover:border-white border-b-2 transition text-xl text-white text-center w-32`}>
                        Amis
                    </Link>

                    <Link to="/profile/posts" className={`p-3 ${window.location.pathname === '/profile/posts' ? "border-white" : "border-base-100"} hover:border-white border-b-2 transition text-xl text-white text-center w-32`}>
                        Publications
                    </Link>

                    <Link to="/profile/stats" className={`p-3 ${window.location.pathname === '/profile/stats' ? "border-white" : "border-base-100"} hover:border-white border-b-2 transition text-xl text-white text-center w-32`}>
                        Statistiques
                    </Link>
                </div>
                <Outlet context={authUser} />
            </div>
            <Footer/>
        </div>
    )
}

export default ProfileAuth

