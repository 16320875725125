import React, {useEffect, useState} from "react";


const AvatarPicture = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const DEFAULT_URL =  "https://media.tenor.com/I6kN-6X7nhAAAAAi/loading-buffering.gif" // "https://robohash.org/_?set=set2"

    useEffect(() => {
        setIsLoading(true)
    }, [props.url])

    return (
        <>
            {isLoading ? (
                <>
                    <img src={DEFAULT_URL}
                         className="max-w-40 rounded-full peer-checked:border-black border-2"/>

                    <img src={`https://robohash.org/${props.url}.png?set=set2`}
                     className="hidden"
                     onLoad={() => setIsLoading(false)}/>
                </>
            ) : (
                <img src={`https://robohash.org/${props.url}.png?set=set2`}
                     className="max-w-40 rounded-full peer-checked:border-black border-2"/>
            )}
        </>
    )
}

export default AvatarPicture;